import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import SearchResults from "../components/search/SearchResults"
import Seo from "../components/Seo"

const Search = ({ location }) => {
  const q = useStaticQuery(graphql`
    {
      localSearchAllPages {
        index
        store
      }
    }
  `)

  const index = q.localSearchAllPages.index
  const store = q.localSearchAllPages.store

  return (
    <>
      {location.state && (
        <>
          <Seo title={`${location.state.search} - Search`} />
          <h1>{`Results for: "${location.state.search}"`}</h1>
          <hr />
          {location.state && (
            <SearchResults
              search={location.state.search}
              index={index}
              store={store}
            />
          )}
        </>
      )}
    </>
  )
}

export default Search
