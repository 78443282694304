import React from "react"
import { useFlexSearch } from "react-use-flexsearch"
import SummaryCard from "../SummaryCard"

const SearchResults = ({ search, index, store }) => {
  const results = useFlexSearch(search, index, store)

  return (
    <>
      {results.length ? (
        results.map(result => (
          <SummaryCard
            key={result.id}
            title={result.title}
            path={result.path}
            date={result.date}
            wordCount={result.words}
            excerpt={result.excerpt}
          />
        ))
      ) : (
        <h3>No results found...</h3>
      )}
    </>
  )
}

export default SearchResults
