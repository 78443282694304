import React from "react"
import { Link } from "gatsby"

const SummaryCard = props => {
  return (
    <div>
      <h3 className="listHeader">{props.title}</h3>
      {props.date && (
        <>
          <small>Published {props.date}</small>
          <br />
        </>
      )}
      {props.wordCount && (
        <>
          <small>({props.wordCount} words)</small>
          <br />
        </>
      )}
      {props.excerpt && <p className="excerpt">{props.excerpt}</p>}
      <Link to={props.path}>Read more</Link>
    </div>
  )
}

export default SummaryCard
